<template>
  <div>
    <el-button type="primary" @click="openDialog">点击弹出表单页面</el-button>
    <el-dialog
      :visible.sync="dialogVisible"
      title="上传图片"
      :before-close="beforeClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="选择图片">
          <el-upload
            action="http://www.soutudashi.com/api/upload"
            :auto-upload="false"
            :data="{ type: form.bz_type }"
            show-file-list
            list-type="picture-card"
            :limit="1"
            :file-list="fileList"
            ref="upload"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            :on-remove="handleRemove"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选择图片</el-button
            >
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="图片标签"> -->
        <!-- <el-input v-model="form.label"></el-input> -->
        <!-- </el-form-item> -->
        <el-form-item label="图片类型" style="text-align: left">
          <el-select v-model="form.bz_type" clearable placeholder="请选择">
            <el-option
              v-for="item in type_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片风格"
          ><el-input placeholder="请输入内容" v-model="form.bz_style" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="图片背景色"
          ><el-input
            placeholder="请输入内容"
            v-model="form.bz_bg_color"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="图片主体内容"
          ><el-input
            placeholder="请输入内容"
            v-model="form.main_content"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="图片标签"
          ><el-input placeholder="请输入内容" v-model="form.bz_label" clearable>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="submitUpload">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        bz_type: "",
        bz_style: "",
        bz_bg_color: "",
        main_content: "",
        bz_label: "",
        image: null,
      },
      fileList: [],
      type_options: [
        { value: "壁纸", label: "壁纸" },
        { value: "头像", label: "头像" },
        { value: "背景图", label: "背景图" },
      ],
      // formData: new FormData(),
    };
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },

    closeDialog() {
      // console.log("调用关闭对话框函数");
      this.clear_data();

      this.dialogVisible = false;

      // 关闭对话框就清空数据
    },

    beforeClose() {
      //  console.log("关闭对话框前面执行的操作");
      this.closeDialog();
      // this.clear_data();
      // this.$refs.form.resetFields();

      this.fileList = [];
    },

    handleRemove(file, fileLis) {},

    handleSuccess(response) {
      // console.log("ssssss: ", response);
      // console.log("form: ", this.form);
      this.form.image = response.data;
      //  console.log("执行陈公公后: ", this.file_upload_data);

      try {
        let data_obj = {
          bz_type: this.form.bz_type,
          bz_style: this.form.bz_style,
          bz_bg_color: this.form.bz_bg_color,
          main_content: this.form.main_content,
          bz_label: this.form.bz_label,
          width: this.form.image.width,
          height: this.form.image.height,
          name: this.form.image.name,
        };
        // console.log("formData:", data_obj);

        this.$http({
          method: "post",
          url: "http://www.soutudashi.com/api/submit",
          data: data_obj,
        }).then((resp) => {
          //  console.log("是的告诉大家根深蒂固: ", resp);
          if (resp.status == 200) {
            this.$message({
              message: "提交成功，请等待管理员审核！",
              type: "success",
            });
            //提交成功重置form表单
            this.$refs.form.resetFields();
            //browse
            // alert("提交成功，请等待审核！");
            // this.$router.push({ path: "/browse" });
          } else {
            this.$message({
              message: "系统出错了，请联系管理员！",
              type: "error",
            });
            // this.$router.push({ path: "submit" });
          }
        });
      } catch (error) {
      } finally {
        this.closeDialog();
        // 不论是否成功，清除数据
        // this.clear_data();
      }

      //   this.form.image = response.data.url;
    },

    // 清空数据
    clear_data() {
      this.form = {
        bz_type: "",
        bz_style: "",
        bz_bg_color: "",
        main_content: "",
        bz_label: "",
        image: null,
      };
      this.fileList = [];
    },
    // submitUpload() {
    //   console.log("上传~~~~~~~~~~~");
    //   // this.$refs.form.submit();
    //   this.$refs.upload.submit();
    // },

    submitUpload() {
      var fileName = this.fileName;
      if (fileName == "") {
        this.$message({
          message: "请选择上传文件！",
          type: "error",
        });
        return;
      }

      this.$refs.form.validate(async (valid) => {
        //   console.log("是否有效: ", valid);
        if (valid) {
          let image_obj = {};
          try {
            let res = await this.$refs.upload.submit();
            //   console.log("图片名称~~~~~~~~~~~: ", res);
          } catch (error) {
            //   console.log("submit失败");
          }
          // console.log("this.form前: ", this.form);
          // console.log("文件上传独享: ", this.file_upload_data);
          // Object.assign(this.form, this.file_upload_data);
          // console.log("this.form后: ", this.form);
          //获取form表单的参数
          // let formData = new FormData();
          // for (let key in this.form) {
          //   console.log("form key: ", this.form);
          //   this.formData.append(key, this.form[key]);
          //   // console.log(key + "----" + formData.get(key));
          // }

          // 从form获取表单数据
          // const formData = new FormData(this.$refs.form.$el);
          // // 拼接文件数据
          // for (let [key, value] of this.formData.entries()) {
          //   formData.append(key, value);
          // }
        } else {
          //  console.log("error submit!!");
          return false;
        }
      });
    },

    uploadData() {
      // console.log("提交上传图片请求~~~~~~~~~~~~~~");
    },
    beforeUpload(file) {
      // 在这里可以对上传的文件进行处理，比如添加到formData
      // console.log("执行上传前: ", file);
      // this.formData.append("file", file);
      return true; // 阻止默认的上传行为
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-left: 100px;
}

.el-form-item__content {
  margin-left: 100px;
}
.el-form-item__label {
  width: 100px;
}
</style>
