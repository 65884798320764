<template>
  <div id="app">
    <div>
      <AddElement></AddElement>
    </div>
    <h1>后台管理页面</h1>
    <table>
      <thead>
        <tr>
          <th>图片</th>
          <th>名称</th>
          <th>分类</th>
          <th>风格</th>
          <th>背景色</th>
          <th>主体</th>
          <th>标签</th>
          <th>分辨率</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index" class="item_ele">
          <td><img :src="item.url" /></td>
          <td>{{ item.pic_name }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.pic_cate }}</td>
          <td>{{ item.width }}</td>
          <td>{{ item.height }}</td>
          <td>
            <input v-if="editableRows[index]" type="text" :value="item.color" />
            <span v-if="!editableRows[index]">{{ item.color }}</span>
          </td>
          <td>
            <input v-if="editableRows[index]" type="text" :value="item.body" />
            <span v-if="!editableRows[index]">{{ item.body }}</span>
          </td>
          <td>
            <input v-if="editableRows[index]" type="text" :value="item.flag" />
            <span v-if="!editableRows[index]">{{ item.flag }}</span>
          </td>

          <!-- <td>{{ item.color }}</td>
          <td>{{ item.body }}</td>
          <td>{{ item.flag }}</td> -->
          <td class="btn-td">
            <button @click="editItem(index)">编辑</button>
            <button @click="deleteItem(index)">删除</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="bz_page_part">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page_no"
        :page-size="page_size"
        layout="prev, pager, next"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script type="text/babel">
import axios from "axios";
import AddElement from "./AddElement.vue";
export default {
  data() {
    return {
      items: [],
      newItem: {
        name: "",
        description: "",
      },
      page_no: 1,
      page_size: 10,
      total: 1000,
      editableRows: Array(10).fill(true), // 初始状态下所有行不可编辑
    };
  },
  methods: {
    async getItems(page_no, page_size) {
      // console.log(
      //   "调用getItems~~~~~~~~~~~~~~~~~~",
      //   page_no,
      //   page_size,
      //   this.page_no,
      //   this.page_size,
      //   this.items
      // );
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/get_pic_info",
          {
            headers: { "Content-Type": "application/json" },
            page_num: page_no,
            page_size: page_size,
          }
        );

        // console.log("data:", response, response.data);
        return response.data;
      } catch (e) {
        // console.log(e);
      } finally {
        this.reset_editable_data();
      }

      //   const response = await axios.get("http://localhost:3000/data");
      //   this.items = response.data;
    },
    async addItem() {
      //   await axios.post("http://localhost:3000/data", this.newItem);
      //   this.newItem = { name: "", description: "" };
      //   this.getItems(this.page_no, this.page_size);
    },
    async editItem(rowIndex) {
      this.editableRows[rowIndex] = !this.editableRows[rowIndex];
      // console.log(rowIndex, this.items[rowIndex], this.editableRows[rowIndex]);

      let current_item = this.items[rowIndex];

      let send_data = {
        bz_type: current_item.bz_type,
        pic_cate: current_item.pic_cate,
        color: current_item.color,
        flag: current_item.flag,
        body: current_item.body,
        pic_name: current_item.pic_name,
        width: current_item.width,
        height: current_item.height,
      };
      // console.log("发送的数据: ", send_data);
      // try {
      //   var response = await this.$http.post(
      //     "http://www.soutudashi.com/api/update_bz_data",
      //     {
      //       headers: { "Content-Type": "application/json" },
      //       send_data,
      //     }
      //   );

      //   console.log("data: ", response, response.data);
      //   this.reset_editable_data();
      //   return response.data;
      // } catch (e) {
      //   console.log(e);
      // }
      // this.reset_editable_data();
    },
    async deleteItem(id) {
      //   await axios.delete(`http://localhost:3000/data/${id}`);
      //   this.getItems(this.page_no, this.page_size);
    },

    async handleCurrentChange(val) {
      this.page_no = val; // 改变当前页码

      this.items = await this.getItems(val, this.page_size);
    },
    handleSizeChange(val) {
      this.page_size = val; // 改变每页显示的数量
      this.page_no = 1; // 重置当前页码
    },

    // 重置可编辑数据
    reset_editable_data() {
      this.editableRows = Array(10).fill(false); // 初始状态下所有行不可编辑
    },
  },
  async mounted() {
    this.items = await this.getItems(this.page_no, this.page_size);
  },

  components: { AddElement },
};
</script>

<style scoped>
image {
  height: 100px;
}

tr {
  display: flex;
  justify-content: left;
  align-items: center;
}

table {
  padding: 10px 100px;
  margin: 0px auto;
}

td,
th {
  margin: 0px 20px;
  width: 150px;
}

.btn-td {
  display: flex;
  justify-content: space-between;
}
</style>
